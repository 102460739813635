import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

//  引入需要的语言包
import en from "@/lang/en.json";
import zh from "@/lang/zh.json";

let json = JSON.parse(localStorage.getItem('store'))||{}
// 本地语言环境获取
const lang = json.language?.value || 'en-us'

const i18n = new VueI18n({
  locale: lang, // 语言标识
  fallbackLocale: "en-us", // 失败时默认语言
  silentTranslationWarn: true, // 设置为true 会屏蔽翻译在控制台报出的警告
  messages: {
    'en-us': en,
    'zh-cn': zh,
  },
});

export default i18n;

