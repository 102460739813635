import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from "vuex-persistedstate";
import { getInfo } from '@/api'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: {
      value:'en-us',
      name:"English"
    },
    defaultIndex:0,
    isShow:false,
    userInfo: '',
    token: '',
    phone: "",
    isShowGg:true,
    isOneUrl:true
  },
  getters: {
  },
  mutations: {
    changeLanguage(state, value) {
      state.language = value;
    },
    changeIsShow(state, value) {
      state.isShow = value;
    },
    changeDefaultIndex(state, value) {
      state.defaultIndex = value;
    },
    setUserInfo(state, value) { //用户信息
      state.userInfo = value;
    },
    setToken(state, value) { //token
      state.token = value;
    },
    changePhone(state, value) {
      state.phone = value;
    },
    changeIsShowGg(state, value) {
      state.isShowGg = value;
    },
    changeIsOneUrl(state, value) {
      state.isOneUrl = value;
    },
  },
  actions: {
    // 获取用户信息
    getInfo(context, params) {
      return new Promise((resolve, reject) => {
        getInfo({
          token: context.state.token,
        }, params).then(res => {
          if (res.code == 1) {
            context.commit('setUserInfo', res.data);
            resolve(res.data);
          }
        })
      })
    },
  },
  modules: {
  },
  /* vuex数据持久化配置 */
  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies  默认: localStorage
      storage: window.localStorage,
      // 存储的 key 的key值
      key: "store",
      render(state) {
        // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
        return { ...state };
      }
    })
  ]
})
